import React, { useEffect } from "react";
import { renderToStaticMarkup } from "react-dom/server";

const Layout = (prop) => {
  const format = prop.format;
  const card = prop.card;

  const SvgBackground = (prop) => {
    return (
      <svg
        height="346"
        viewBox="0 0 300 346"
        width="300"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none" fill-rule="evenodd" transform="translate(0 -18)">
          <rect
            fill={format.border_color || "#982f4a"}
            height="346"
            rx="7.89"
            width="300"
            y="18"
          />
          <rect
            fill={format.background_color || "#f9f4eb"}
            height="323"
            rx="7.26"
            transform="matrix(-1 0 0 -1 302 383)"
            width="272"
            x="15"
            y="30"
          />
          <path
            d="m154.705991 75.9587041c-.250163-.0030211-.497993-.0400704-.733439-.1096455-13.025589-3.9626644-25.278545-9.466328-36.371921-16.3371846-8.567904-5.3452198-21.5364438-13.4178723-28.3374262-25.8215234-2.1336416-3.7964766-5.6341472-10.1422119-3.7005346-17.3377001 2.9170881-11.27978438 16.2856858-14.95290979 16.4190388-14.95290979 7.73445-1.93250254 17.419183-1.86397408 28.787491.20558538 22.619934 4.11170754 43.706314 14.48691621 57.591653 21.92910691 15.285541 8.2234151 18.502673 11.9239519 19.552824 13.1437584 8.617912 9.9914493 9.484704 22.9159167 2.000289 30.0565822-5.667485 5.4000425-16.535722 7.82595-29.820974 6.6472605-14.068699-1.247218-29.087535-6.4142638-42.306111-14.5280333-.595529-.3672429-.907537-.9678184-.818494-1.5754955.089043-.607677.565609-1.1301351 1.25018-1.3705692.684572-.2404341 1.473146-.1623167 2.068675.2049263 12.618489 7.784833 26.953893 12.7051763 40.289153 13.8838658 11.668352 1.0416326 21.519775-1.0005155 26.203785-5.4822767 6.167558-5.8797418 5.184082-17.2417603-2.233656-25.8489348-2.51703-2.9193123-8.717926-7.0447256-18.435996-12.2528884-13.635303-7.2777224-34.271617-17.44734571-56.241458-21.44940772-10.668207-1.93250254-19.636169-2.02844238-26.670519-.27411383-.116683 0-11.1849489 3.1797205-13.6019647 12.33512265-1.6669075 6.0853271 1.5502239 11.7868949 3.3338149 15.1996122 6.4175937 11.6087209 18.3359818 19.0646173 27.1039148 24.5057769 10.785377 6.706647 22.708297 12.0769878 35.388446 15.9397196.964869.2770083 1.542967 1.0885178 1.364887 1.9159711-.178081.8274533-1.059767 1.4265698-2.081658 1.414512z"
            fill={format.border_color || "#982f4a"}
          />
        </g>
      </svg>
    );
  };

  const svgString = encodeURIComponent(
    renderToStaticMarkup(<SvgBackground format={prop.format} />)
  );
  const dataUri = `url("data:image/svg+xml,${svgString}")`;

  return (
    <div
      className="card relative rounded-lg border-8 bg-white p-8 flex flex-col items-center cursor-pointer"
      style={{ background: dataUri, border: "none" }}
    >
      <div className="div h-full"></div>
      <div className="div h-full flex items-center">
        <h3
          className="text-center font-bold uppercase"
          style={{
            color: prop.format.color
          }}
        >
          {card.card_text}
        </h3>
      </div>
      <div className="div h-full flex flex-row items-end">
        <div className="text-center text-sm">
          <p
            className="text-sm leading-snug"
            style={{
              fontSize: "12px"
            }}
          >
            {card.note}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Layout;

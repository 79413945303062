import React, { useEffect } from "react";
import { renderToStaticMarkup } from "react-dom/server";

const Layout = (prop) => {
  const format = prop.format;
  const card = prop.card;

  const SvgBackground = (prop) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="300"
        height="345"
        viewBox="0 0 300 345"
      >
        <defs>
          <path id="a" d="M0 0L243 0 243 68 0 68z"></path>
          <path id="c" d="M0 0L9 0 9 9 0 9z"></path>
          <path id="e" d="M0 0L9 0 9 9 0 9z"></path>
          <path id="g" d="M0 0L9 0 9 9 0 9z"></path>
        </defs>
        <g fill="none" fillRule="evenodd">
          <rect
            width="299"
            height="344"
            x="0.5"
            y="0.5"
            fill="#F8EEDF"
            stroke="#86A78D"
            strokeOpacity="0.617"
            rx="8"
          ></rect>
          <g transform="translate(29 20)">
            <mask id="b" fill="#fff">
              <use xlinkHref="#a"></use>
            </mask>
            <path
              fill="#86A78D"
              d="M.022 32.226c.38-4.322 5.414-5.975 24.34-15.992C40.616 7.63 39.799 7.319 44.918 5.345 51.807 2.688 62.263-.204 75.894.264a207.522 207.522 0 0037.789 3.389A207.86 207.86 0 00151.929 0a204.103 204.103 0 0021.444 4.79c18.246 3.055 22.388 1.175 35.967 4.912 18.878 5.193 25.16 12.755 27.703 16.33 3.152 4.433 4.94 8.896 5.957 11.978a65.725 65.725 0 01-5.041 5.902c-7.92 8.213-17.556 13.222-44.704 19.141A401.655 401.655 0 01166.094 68c-13.752-3.827-25.158-4.886-33.392-5.085 0 0-5.61-.134-32.382 1.43 0 0-42 .16-48.552-1.656-6.554-1.815-18.171-8.347-25.022-8.347-6.851 0-12.212-6.534-12.212-6.534C.438 39.633-.156 34.247.022 32.226"
              mask="url(#b)"
            ></path>
          </g>
          <g transform="translate(123 317)">
            <mask id="d" fill="#fff">
              <use xlinkHref="#c"></use>
            </mask>
            <path
              fill="#5E7D65"
              d="M9 4.5a4.5 4.5 0 11-9 0 4.5 4.5 0 119 0"
              mask="url(#d)"
            ></path>
            <g transform="translate(22)">
              <mask id="f" fill="#fff">
                <use xlinkHref="#e"></use>
              </mask>
              <path
                fill="#5E7D65"
                d="M9 4.5a4.5 4.5 0 11-9 0 4.5 4.5 0 119 0"
                mask="url(#f)"
              ></path>
            </g>
            <g transform="translate(45)">
              <mask id="h" fill="#fff">
                <use xlinkHref="#g"></use>
              </mask>
              <path
                fill="#5E7D65"
                d="M9 4.5a4.5 4.5 0 11-8.999 0 4.5 4.5 0 019 0"
                mask="url(#h)"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    );
  };

  const svgString = encodeURIComponent(
    renderToStaticMarkup(<SvgBackground format={prop.format} />)
  );
  const dataUri = `url("data:image/svg+xml,${svgString}")`;

  return (
    <div className='card relative flex flex-col justify-top items-center cursor-pointer' style={{ background: dataUri, border: 'none' }}>
      <div className="flex flex-col h-full">
        <div className="h-1/4 flex flex-col justify-top align-center pt-4">
          <div className="flex flex-col h-full justify-center align-center">
            <div className="flex flex-col flex-grow justify-center align-center">
              <h3 className='text-center font-bold uppercase px-8' style={{ color: prop.format.background_color }}>{card.title}</h3>
            </div>
          </div>
        </div>
        <div className='text-center text-sm px-8 flex flex-col flex-grow justify-center align-center pb-8'>
          <p className='text-sm leading-tight'><strong>{card.card_text}</strong>{card.note}</p>
        </div>
      </div>
    </div>
  );
};

export default Layout;

import React, { useEffect } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'

const Layout=(prop) => {
  const format = prop.format;
  const card = prop.card;

  const SvgBackground=(prop) => {
    return(
      <svg height="346" viewBox="0 0 300 346" width="300" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fill-rule="evenodd">
          <path fill={prop.format.border_color} d="m299.188775 13.0608422v319.8316908c-.027483 7.211488-7.079571 13.051902-15.787181 13.074664h-267.6311013c-8.70488548-.022799-15.7521714-5.865412-15.7704927-13.074664v-319.8316908c.0183591-7.20699661 7.06830601-13.04563752 15.7704927-13.0608422h267.6311013c8.70491.01517338 15.759654 5.8516105 15.787181 13.0608422z"  />
          <rect fill={prop.format.background_color || "#f9efdf"} height="322.609712" rx="8.7" width="271.619618" x="13.784579" y="11.664922" />
          <ellipse cx="135.6075" cy="261.500876" fill="#e94a3d" rx="2.935833" ry="2.850359" />
          <ellipse cx="150.265" cy="261.500876" fill="#9bd4dc" rx="2.935833" ry="2.850359" />
          <ellipse cx="164.9225" cy="261.500876" fill="#f4a916" rx="2.935833" ry="2.850359" />
          <path fill={prop.format.border_color} d="m85 0h129.872902v17.7435124c-.008882 5.6077566-4.608473 10.1590251-10.302693 10.1944544h-109.2183772c-5.713419-.0088817-10.342813-4.5678958-10.3518318-10.1944544z"/>
        </g>
      </svg>
    )
  }

  const svgString = encodeURIComponent(renderToStaticMarkup(<SvgBackground format={prop.format}/>));
  const dataUri = `url("data:image/svg+xml,${svgString}")`;

  return(
    <div className='card relative rounded-lg border-8 bg-white p-8 flex flex-col justify-center items-center cursor-pointer' style={{background: dataUri, border: 'none'}}>
      <h4 className='text-center absolute top-1 text-sm uppercase' style={{color: '#f9efdf'}}>{card.title}</h4>

      <h3 className='text-center font-bold uppercase' style={{color: prop.format.color, backgroundColor: prop.format.background_color }}>{card.card_text}</h3>
      <div className='text-center absolute bottom-5 text-sm px-8'>
        <p className='text-sm leading-none' style={{backgroundColor: prop.format.background_color}}>{card.note}</p>
      </div>
    </div>
  )
}

export default Layout;
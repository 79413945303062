import React, { useEffect } from "react";
import { renderToStaticMarkup } from "react-dom/server";

const Layout = (prop) => {
  const format = prop.format;
  const card = prop.card;

  const SvgBackground = (prop) => {
    return (
      <svg height="345" viewBox="0 0 300 345" width="300"
        xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="nonzero">
          <g transform="translate(-.000008)">
            <path d="m300 13.0243289v318.9375601c0 7.166826-4.298519 13.038111-13 13.038111h-275c-8.70146458 0-12-5.871285-12-13.038111v-318.9375601c0-7.1668265 6.29853542-13.0243289 15-13.0243289h71.2951887c-1.0374833 3.74880153-1.5729585 7.63542665-1.5729585 11.6323106 0 29.1083414 28.6311918 52.7037393 63.9725568 52.7037393 35.341366 0-69.3791814-24.2277086-69.3791814-53.3360499 0-3.99688399-9.6803681-7.25119847-10.7178514-11h218.4022458c8.701481 0 13 5.8575024 13 13.0243289z" fill={prop.format.border_color} />
            <rect fill="#f9f4eb" height="322" rx="8.7" width="274" x="12.000008" y="11" />
          </g>
          <g>
            <circle cx="136.11" cy="131.45" fill="#f49540" r="2.71" />
            <circle cx="149.64" cy="131.45" fill="#0d466b" r="2.71" />
            <circle cx="163.17" cy="131.45" fill="#982f4a" r="2.71" />
          </g>
        </g>
      </svg>
    );
  };

  const svgString = encodeURIComponent(
    renderToStaticMarkup(<SvgBackground format={prop.format} />)
  );
  const dataUri = `url("data:image/svg+xml,${svgString}")`;

  return (
    <div className='card relative flex flex-col justify-top items-center cursor-pointer' style={{ background: dataUri, border: 'none' }}>
      <div className="flex flex-col h-full">
        <div className="h-1/3 flex flex-col justify-top align-center pt-3">
          <div className="flex flex-col h-full justify-center align-center">
            <div className="flex flex-col flex-grow justify-center align-center">
              <h3 className='text-center font-bold uppercase px-8' style={{ color: prop.format.border_color }}>{card.card_text}</h3>
            </div>
          </div>
        </div>
        <div className='text-center text-sm px-8 flex flex-col flex-grow justify-center align-center'>
          <p className='text-sm leading-tight' style={{ backgroundColor: prop.format.background_color }}>{card.note}</p>
        </div>
      </div>
    </div>
  );
};

export default Layout;

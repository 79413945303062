import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react'
import ReactDOM from 'react-dom'
import TinderCard from 'react-tinder-card'

import Layout1 from './card_layouts/layout_1'
import Layout2 from './card_layouts/layout_2'
import Layout3 from './card_layouts/layout_3'
import Layout4 from './card_layouts/layout_4'
import Layout5 from './card_layouts/layout_5'


const TinderCards =(prop) => {
  const [lastDirection, setLastDirection] = useState()
  const [cards, setCards] = useState([])
  const [currentIndex, setCurrentIndex] = useState(1)
  const [favorites, setFavorites] = useState([])

  const [length, setLength] = useState(prop.cards.length)
  const activeFavoriteBtn = useRef()

  const setCardList = useCallback(cards => {
    setCards(cards)
  }, [setCards])

  useEffect(() => {
    setCards(prop.cards)
    setFavorites(prop.favorites)
    setKeyListener()
  }, [])

  const setKeyListener=() => {
    document.addEventListener('keyup', (e) => {
      if(e.code == 'KeyK') {
        swipe('right')
      } else if (e.code == 'KeyF') {
        activeFavoriteBtn.current.click()
      }
    });
  }

  const onSwipe = (direction) => {
    console.log('You swiped: ' + direction)
  }

  // swipe #########################
  const childRefs = useMemo(
    () =>
      Array(length)
        .fill(0)
        .map((i) => React.createRef()),
    []
  )

  const swipe = async (dir) => {
    await childRefs[length - 1].current.swipe(dir) // Swipe the card!
  }

  const outOfFrame = (myIdentifier) => {
    console.log(myIdentifier + ' left the screen')
  }

  const swiped = (direction, nameToDelete) => {
    console.log('removing: ' + nameToDelete)
    console.log('swipe: ' + direction)

    setTimeout(() => { addAgainToList(nameToDelete) }, 500)
  }

  const saveToFavorite=(nameToDelete) => {
    console.log('saved to favourites!')
    //create a new list record
    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': csrf },
      body: JSON.stringify({ card_data: {card_id: nameToDelete } })
    }

    fetch('/add_to_list', requestOptions)
      .then(response => {
        response.json()
          .then(data => {
            setFavorites(data)
          })
      });
  }

  const addAgainToList=(id) => {
    const card = cards.find((card) => card.id == id)

    if(card) {
      let nextIndex = cards.length - card.card_order + 1
      if(nextIndex > cards.length) { nextIndex = 1 }
        
      setCurrentIndex(nextIndex)

      let newCards = cards
      newCards.splice(newCards.indexOf(card))
      newCards = [...[card], ...newCards]

      setCards(newCards)
    }
  }

  const Layout=(prop) => {
    const type = prop.format.layout_type;

    switch(type) {
      case 2:
        return (
          <Layout2 card={prop.card} format={prop.format}/>
        )
        break;
      case 3:
        return (
          <Layout3 card={prop.card} format={prop.format}/>
        )
        break;
      case 4:
        return (
          <Layout4 card={prop.card} format={prop.format}/>
        )
        break;
      case 5:
        return (
          <Layout5 card={prop.card} format={prop.format}/>
        )
        break;
      default:
        return (
          <Layout1 card={prop.card} format={prop.format}/>
        )
    }
  }

  const TinderCardItem=(prop) => {
    const card = prop.card

    const isFavorite=(card) => {
      return favorites.indexOf(card.id) > -1
    }

    return(
      <TinderCard ref={childRefs[prop.index]} className='absolute' key={card.id} onSwipe={(dir) => swiped(dir, card.id)} onCardLeftScreen={() => outOfFrame(card.id)}>
        <Layout card={card} format={prop.format}/>

        <button ref={activeFavoriteBtn} onTouchStart={() => { saveToFavorite(card.id)} } onClick={() => { saveToFavorite(card.id)} } className='rounded-full float-right mt-2 bg-white drop-shadow-md p-2 hover:bg-blue cursor-pointer'>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill={isFavorite(card) ? "#eb4d59" : 'white'} viewBox="0 0 24 24" stroke="#eb4d59">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
          </svg>
        </button>
      </TinderCard>
    )
  }

  return(
    <>
      {
        cards.map((card, index) => (
          <TinderCardItem key={card.id} card={card} format={prop.card_format} index={index} note={prop.note} total={cards.length}/>
        ))
      }

      <div className='text-center p-2 card-counter'>Card {currentIndex} of {cards.length}</div>
    </>
  );
}

const loadCardsData=() => {
  const node = document.getElementById('cards_data')
  
  if(node) {
    const data = JSON.parse(node.getAttribute('data'))
    const favorites = JSON.parse(node.getAttribute('favorites'))
    const card_format = JSON.parse(node.getAttribute('card_format'))
    const note = node.getAttribute('note')

    ReactDOM.render(
      <TinderCards cards={data} favorites={favorites} card_format={card_format} note={note}/>, 
      document.getElementById('cardContainer').appendChild(document.createElement('div'))
    )
  }
}

document.addEventListener('turbolinks:load', () => {
  loadCardsData()
})
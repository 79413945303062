import React, { useEffect } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'

const Layout=(prop) => {
  const format = prop.format;
  const card = prop.card;

  const SvgBackground=(prop) => {
    return(
      <svg height="346" viewBox="0 0 300 346" width="300" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fill-rule="evenodd">
          <rect fill={prop.format.border_color} height="345" rx="10" stroke="#000" width="299" x=".5" y=".5"/>
          <rect fill={prop.format.background_color || "#fff"} height="326" rx="10" width="280" x="10" y="10"/>
        </g>
      </svg>
    )
  }

  const svgString = encodeURIComponent(renderToStaticMarkup(<SvgBackground format={prop.format}/>));
  const dataUri = `url("data:image/svg+xml,${svgString}")`;

  return(
    <div className='card relative rounded-lg border-8 bg-white p-8 flex flex-col justify-center items-center cursor-pointer' style={{background: dataUri, border: 'none'}}>
      <h3 className='text-center font-bold uppercase' style={{color: prop.format.color, backgroundColor: prop.format.background_color }}>{card.card_text}</h3>
    </div>
  )
}

export default Layout;